import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import store from 'store';

// Components
import Sidebar from './Sidebar';
import Navbar from './Navbar';

// Styles
import { Logo } from './Styles';
import { updateRoleType } from 'services/api/UserManagement';
import { redirect } from 'next/dist/server/api-utils';

const activeList = {
  dashboard: 'dashboard',
  question_bank: 'question_bank',
  library: 'library',
  analytic: 'analytic',
  discussion: 'discussion',
  discussion_admin: 'discussion_admin',
  course_management: 'course_management',
  marketplace_management: 'marketplace_management',
  notification_management: 'notification_management',
  discount_management: 'discount_management',
  'user-management': 'user-management',
  'account-settings': 'account-settings',
  reports: 'reports',
  achievement: 'achievement',
  'my-products': 'my-products',
  discussion_course: 'discussion_course',
};

const exception = {
  '/': '/',
};

const Frame = (props) => {
  const { children } = props;
  const { asPath, ...router } = useRouter();

  const [activeTab, setActiveTab] = useState('dashboard');
  const [isNeedFrame, setIsNeedFrame] = useState(true);
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(null);

  const [hideSidebar, setHideSidebar] = useState(false);
  const [hideSidebarMobile, setHideSidebarMobile] = useState(false);

  const notAllowedMenu = {
    facilitator: {
      menu: ['/dashboard', '/user-management', '/marketplace_management', '/question_bank', '/notification_management'],
      redirect: '/course_management',
    },
    examiner: {
      menu: [
        '/dashboard',
        '/user-management',
        '/discussion',
        '/marketplace_management',
        '/question_bank',
        '/notification_management',
      ],
      redirect: '/course_management',
    },
    course_administrator: {
      menu: ['/user-management/roles'],
      redirect: '/dashboard',
    },
    learner: {
      menu: [
        '/user-management',
        '/course_management',
        '/marketplace_management',
        '/question_bank',
        '/notification_management',
        '/reports',
      ],
      redirect: '/dashboard',
    },
  };

  // Check if user dont has a access token, auto redirect to login
  useEffect(() => {
    const lerero_lms = store.get('lerero_lms');

    if (
      asPath !== '/' &&
      !asPath.includes('/set-password/') &&
      !asPath.includes('/forgot-password') &&
      !asPath.includes('/set-account/') &&
      !asPath.includes('/activation-link') &&
      !lerero_lms &&
      !lerero_lms?.accessToken
    ) {
      Router.push({ pathname: '/' });
      setTimeout(() => {
        setReady(true);
      }, 500);
    } else {
      const lerero_lms = store.get('lerero_lms');
      const roleType = lerero_lms?.user?.roleType;

      if (notAllowedMenu[roleType] && notAllowedMenu[roleType].menu.some((item) => asPath.includes(item))) {
        Router.push({ pathname: notAllowedMenu[roleType].redirect });
        setTimeout(() => {
          setReady(true);
        }, 500);
      } else {
        setReady(true);
      }

      // if (
      //   roleType !== 'super_administrator' &&
      //   (asPath.includes('/user-management') ||
      //     asPath.includes('/course_management') ||
      //     asPath.includes('/question_bank') ||
      //     asPath.includes('/marketplace_management') ||
      //     asPath.includes('/notification_management') ||
      //     asPath.includes('/reports'))
      // ) {
      //   if (roleType === 'learner') Router.push({ pathname: '/dashboard' });
      //   else Router.push({ pathname: '/course_management' });
      //   setTimeout(() => {
      //     setReady(true);
      //   }, 500);
      // } else {
      //   setReady(true);
      // }
    }
  }, []);

  useEffect(() => {
    setIsNeedFrame(true);
    const lerero_lms = store.get('lerero_lms');
    setUser(lerero_lms?.user);

    const CheckException = () => {
      if (
        asPath === '/' ||
        asPath.includes('/set-password/') ||
        asPath.includes('/forgot-password') ||
        asPath.includes('/set-account/') ||
        asPath.includes('/activation-link')
      ) {
        setIsNeedFrame(false);
        return;
      }

      const pathname = router.pathname.split('/') || [];
      for (let x = 0; x < pathname.length; x++) {
        const value = [pathname[x]];
        if (exception[value]) {
          setIsNeedFrame(false);
          return;
        }
        if (activeList[value]) {
          setActiveTab(activeList[value]);
          return;
        }
      }
    };
    CheckException();
  }, [asPath]);

  const contentStyle = hideSidebar ? 'content-start-full/content-end' : 'content-start/content-end';

  useEffect(() => {
    const changeScroll = () => {
      let result = '';
      if (hideSidebarMobile) result = 'no-scroll';
      return (document.body.className = result);
    };
    changeScroll();
  }, [hideSidebarMobile]);

  const handleChangeRoleType = async (roleType) => {
    const {data, success, errors} = await updateRoleType({payload: {roleType}})

    if(!success) {
      alert(errors ?? 'Something went wrong');
      window.location.reload();
      return;
    }

    localStorage.setItem("lerero_lms", JSON.stringify(data))
    
    if(roleType == 'facilitator' || roleType == 'examiner') {
      Router.push('/course_management')
    } else { 
      Router.push('/dashboard')
    }
    
    setTimeout(() => {
      window.location.reload()
    }, 500);
    
  }

  return isNeedFrame
    ? ready && (
        <div className="lms-frame">
          {hideSidebarMobile && <div className="backdrop"></div>}
          <Logo className="logo">
            <img draggable={false} src="/logo-sidebar3.svg" style={{ width: '206px', height: '69px' }} alt="Logo" />
          </Logo>
          <Sidebar
            setHideSidebarMobile={setHideSidebarMobile}
            hideSidebarMobile={hideSidebarMobile}
            setHideSidebar={setHideSidebar}
            hideSidebar={hideSidebar}
            activeTab={activeTab}
            user={user}
            handleChangeRoleType={handleChangeRoleType}
          />
          <Navbar
            user={user}
            ready={ready}
            setHideSidebarMobile={setHideSidebarMobile} handleChangeRoleType={handleChangeRoleType}
          />
          <div
            className="content"
            style={{
              gridColumn: contentStyle,
            }}
          >
            {children}
          </div>
        </div>
      )
    : ready && children;
};

export default Frame;
